@import "variables";

footer {
  & a:hover {
    color: $orange;
  }

  .page-container-footer {
    position: relative;
    width: 1366px;
    margin: 0 auto;
    height: 100%;
  }

  .page-container-slim {
    width: 1250px;
    margin: 0 0 0 auto;
    height: 100%;
  }

  .footer-menu-wrapper {
    display: flex;
    width: 331px;
    height: 371px;
    margin-left: auto;
    flex-direction: column;
    justify-content: space-between;

    .footer-menu-item {
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 29px;
      color: $white;

      &:hover {
        color: $orange;
      }
    }
  }

  .section-footer {
    background-color: $grey_footer;

    .wide-darkgrey-bg {
      display: none;
    }

    .wide-darkgrey-bg-l {
      display: none;
    }

    .orange-line {
      position: absolute;
      right: 0;
      top: 85px;
      width: calc(1250px - 291px + 25px);
      height: 43px;
      background-color: $orange;
    }

    .cta-soc-wrapper {
      position: absolute;
      bottom: 120px;

      &.one {
        display: block;
      }

      &.two {
        display: none;
      }
    }

    .h3-footer {
      width: 781px;
      color: $white;
      margin-bottom: 40px;
      font-size: 30px;
      line-height: 48px;
      font-family: $ff_mont;
      font-style: italic;
      font-weight: 800;

      span {
        color: $blue;
      }
    }

    p {
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: $white;
    }

    span.foo-title {
      font-weight: bold;
      color: $blue;
    }

    .foo-title-link {
      color: $blue;
      text-decoration: underline;

      &:hover {
        color: $orange;
      }
    }
  }

  .footer-row-main {
    .footer-col-main {
      &:first-child {
        width: 781px;
        padding-top: 85px;
      }

      &:last-child {
        width: 407px;
        padding: 304px 0 73px;
        background-color: $grey;
      }
    }
  }

  .footer-row-sec {
    display: flex;
    width: 100%;
  }

  .footer-row-sec-col {
    width: 390px;

    &:first-child {
      .footer-item {
        width: 300px;

        &:nth-child(1) {
          margin-bottom: 36px;
        }

        &:nth-child(2) {
          margin-bottom: 48px;
        }
      }
    }

    &:last-child {
      .footer-item {
        & .a-height-100 {
          height: 100%;
        }

        //width: 311px;
        &:nth-child(-n+2) {
          margin-bottom: 34px;
        }

        &:nth-child(3) {
          position: relative;
          padding-left: 21px;
          margin-bottom: 42px;

          &:before {
            position: absolute;
            width: 11px;
            height: 15px;
            left: 0;
            top: 5px;
            content: url(../../common/images/map-marker-blue.png);
          }
        }

        &:nth-child(3):hover {
          &:before {
            content: url(../../common/images/map-marker-orange.png);
          }

          & a {
            color: $orange;
          }
        }

        &:nth-child(4) {
          margin-bottom: 48px;
        }
      }
    }
  }

  .section-bottom-bar {
    height: 72px;
    background-color: $grey;

    .cols-wrapper {
      display: flex;
      justify-content: space-between;
      height: 100%;

      .p-wrapper {
        p {
          font-family: $ff_mont;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;
        }

        a {
          text-decoration: underline;
        }
      }

      .col {
        display: flex;
        align-items: center;
        height: 100%;

        &:first-child {
          p {
            color: $white;
          }
        }

        &:last-child {
          width: 407px;

          .p-wrapper {
            width: 331px;
            margin-left: auto;
          }

          p {
            color: $light_grey;
          }
        }
      }
    }
  }

}

footer {

  #callOrderModalLayer {
    display: none;
    position: fixed;
    justify-content: center;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 150px;
  }

  .call-form-box {
    max-width: 400px;
    min-width: 300px;
    min-height: 398px;
    height: fit-content;
    background: $white;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 48px 24px;
    .to-hide {
      display: none;
    }

    #callOrderBtnClose {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 15px;
      top: 15px;

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  #callOrderModal-body-ok, #callOrderModal-body-err, #callOrderModal-body,  {
    width: 100%;

    .a-wrap {
      width: 100%;
    }

    small {
      display: none;
      position: absolute;
      bottom: 9px;
      left: 7px;
      color: red;
      font-size: 10px;
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 500;
    }

    .cta-button {
      height: 52px;
      width: 100%;
      background-image: url(../../common/images/clc-cta-button-wide.png);
      background-size: 100% 52px;
      background-position: center;
      background-repeat: no-repeat;
      font-family: $ff_mont;
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: $white;
      text-align: center;
      padding-top: 12px;
      margin-top: 25px;

      &.bg_lzy_img {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
      }

      &:hover {
        background-image: url(../../common/images/clc-cta-button-wide-blue.png);
      }
    }
  }

  #callOrderModal-body-ok, #callOrderModal-body-err {
    max-width: 308px;
    //display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    img {
      width: auto;
      height: 88px;
      margin-bottom: 25px;
    }

    .p-wrapper {
      p {
        font-family: $ff_mont;
        color: $grey;
        text-align: center;

        &:first-child {
          font-style: italic;
          font-weight: 800;
          font-size: 22px;
          line-height: 35px;
          margin-bottom: 12px;
        }

        &:last-child {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 29px;
          margin-bottom: 15px;
        }
      }
    }

  }

  #callOrderModal-body {
    //display: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 308px;

    .form-title {
      margin: 0 auto;
    }

    input, textarea {
      min-width: 290px;
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      border-bottom: 1px solid $grey_border;
      margin-bottom: 24px;
      padding-left: 7px;
      color: $grey;
    }

    .floating-label {
      position: absolute;
      pointer-events: none;
      left: 7px;
      top: 5px;
      transition: 0.2s ease all;
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: $light_grey2;
    }

    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label,
    input[type=email]:not(:placeholder-shown):not(:focus) ~ .floating-label,
    textarea:focus ~ .floating-label,
    textarea:not(:focus):valid ~ .floating-label {
      top: -15px;
      left: 7px;
      font-size: 12px;
      line-height: 25px;
      color: #60615E;
      opacity: 0.5;
    }

    .inp-group {
      position: relative;

      &.field-alert {
        small {
          display: block;
        }

        input {
          border-bottom: 1px solid red;
        }
      }
    }

    .form-title {
      font-family: $ff_mont;
      font-style: italic;
      font-weight: 800;
      font-size: 22px;
      line-height: 35px;
      text-align: center;
      color: $blue;
      margin-bottom: 32px;
    }
  }
}


.footer-row-main {
  justify-content: space-between;
  display: flex;
}

.cta-button-footer-wrapper {
  width: 229px;

  .cta-button-footer {
    width: 229px;
    height: 52px;
    background-image: url(../../common/images/cta-button-blue.png);
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    font-family: $ff_mont;
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    padding-left: 29px;
    color: $grey;
    margin-bottom: 40px;

    &.bg_lzy_img {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
    }

    &:hover {
      color: $grey;
      background-image: url(../../common/images/cta-button-white-filled.png);
    }
  }
}

.social-icons {
  display: flex;

  .soc-ico {
    width: 19px;
    height: 19px;

    &:first-child {
      margin-right: 29px;
    }

    svg:hover {
      path {
        fill: $orange;
      }
    }
  }

  //margin-bottom: 120px;
}

.footer-logos-wrapper {
  display: flex;
  margin-bottom: 40px;
}

.logo-footer {
  width: 291px;
  height: 43px;
}
