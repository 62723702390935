@import "variables";
//@import "h1-h6-p";

@media screen and (max-width: 1199px) {
  .page-container {
    width: 960px;
  }

  .page-container {
    &.nav {
      justify-content: space-between;
    }
    .call-cta {
      margin: 0 24px 0 auto;
    }
  }

  .dt-menu-items-wrapper {
    display: none;
  }

  .mob-menu-items-wrapper {
    display: block;
    #menuToggle
    {
      position: relative;
      z-index: 10;
      user-select: none;
    }

    #menuToggle a:hover
    {
      background-color: rgba(255,255,255,0.2);
    }

    #menuToggle input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;

      cursor: pointer;

      opacity: 0; /* hide this */
      z-index: 2; /* and place it over the hamburger */
      -webkit-touch-callout: none;
    }

    /*
     * Just a quick hamburger
     */
    #menuToggle span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: $blue;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
      background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
      opacity 0.55s ease;
    }

    #menuToggle span:first-child {
      transform-origin: 0 0;
    }

    #menuToggle span:nth-last-child(2) {
      transform-origin: 0 100%;
    }

    /*
     * Transform all the slices of hamburger
     * into a crossmark.
     */
    #menuToggle input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: $orange;
    }

    /*
     * But let's hide the middle one.
     */
    #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    /*
     * Ohyeah and the last one should go the other direction
     */
    #menuToggle input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -1px);
    }

    /*
     * Make this absolute positioned
     * at the top left of the screen
     */
    #menu {
      //display: none;
      top: 54px;
      position: absolute;
      width: 300px;
      height: auto;
      margin-right: 100px;
      -webkit-font-smoothing: antialiased;
      /* to stop flickering of text in safari */

      // transform: none;
      transform: translate(100%);
      //transform: translate(-90%);

      // moving animation
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

      //width: max-content;
      //display: none;
      background-color: $orange;
      min-width: 238px;
      padding-top: 10px;
      padding-bottom: 15px;
    }

    #menu {
      //overflow-y: scroll;
    }

    #menu ul {
      margin-bottom: 15px;
    }

    #menu li {
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: $white;
      display: flex;
      align-items: center;
      height: 36px;
      padding-left: 40px;
      padding-right: 20px;
      &.mini-title {
        font-style: italic;
        font-weight: 700;
        font-size: 18px;
        padding-left: 20px;
        //line-height: 26px;
        //border-top: 1px solid rgba(255,255,255,0.5);
        //border-bottom: 1px solid rgba(255,255,255,0.5);
      }
    }

    #menu li a {
      width: 100%;
    }

    /*
     * And let's slide it in from the left
     */
    #menuToggle input:checked ~ div#menu {
      //display: block;
      //transform: none;
      transform: translate(-90%);
    }
  }

  .logo-header {
    flex-grow: unset;
  }
  .menu-item {
    &:nth-child(-n+2) {
      margin-right: 35px;
    }
    &:nth-child(3) {
      margin-right: 14px;
    }
    &:nth-child(4) {
      margin-right: 14px;
    }
  }

}

@media screen and (max-width: 1023px) {

  .page-container {
    width: 720px;
  }

/*here*/


}

@media screen and (max-width: 767px) {
  .page-container {
    width: 340px;
  }

  .cta-button-header {
    display: none;
  }

  .top-bar-items-wrapper {
    a {
      font-size: 14px;
      line-height: 22px;
      &:nth-child(-n+2) {
        margin-right: 15px;
      }
    }
  }
}

@media screen and (max-width: 419px) {
  .page-container {
    width: 320px;
  }
  .logo-header {
    //margin-right: 133px;
    flex-grow: 0;
    img {
      width: 250px;
      height: auto;
    }
  }

}

html,
body {
  overflow-x: hidden;
}
