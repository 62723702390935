@import "variables";
@import "h1-h6-p";

.dt-menu {
  height: 80px;
}

.page-container {
  width: 1134px;
  height: 100%;
  margin: 0 auto;
  &.nav {
    display: flex;
    align-items: center;
  }
}

.bg-img-elem {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.logo-header {
  //margin-right: 133px;
  flex-grow: 1;
  img {
    width: 291px;
    height: 42px;
  }
}

.top-bar {
  height: 36px;
  background-color: $light_blue;
}

.top-bar-items-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  a {
    font-family: $ff_mont;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    &:nth-child(-n+2) {
      margin-right: 30px;
    }
    &:nth-child(2){
      color: $orange;
    }
    &:hover {
      color: $orange;
    }
  }
}

.dt-menu-items-wrapper {
  display: flex;
  align-items: center;
}

.mob-menu-items-wrapper {
  display: none;
}

/* Dropdown Button */
.drop-btn {
  background-color: $white;
  color: $grey;
  display: flex;
  font-family: $ff_mont;
  font-style: normal;
  font-weight: bold;
  font-stretch: 100%;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  cursor: pointer;
}

.t-white {
  color: white;
}

.dropdown {
  position: relative;
  &:nth-child(-n+2) {
    &:before {
      width: 21px;
      height: 24px;
      content: url(../../common/images/drop-sign-down.png);
      text-align: right;
      position: absolute;
      right: -21px;
      top: 2px;
    }
  }
}

.menu-item {
  &:nth-child(-n+2) {
    margin-right: 45px;
  }
  &:nth-child(3) {
    margin-right: 24px;
  }
  &:nth-child(4) {
    margin-right: 24px;
  }
  &:nth-child(5) {
    margin-right: 34px;
  }
}

.dropdown-content {
  width: max-content;
  display: none;
  position: absolute;
  background-color: $orange;
  min-width: 238px;
  padding-top: 10px;
  padding-bottom: 15px;
  z-index: 1;
}

.dropdown-content a {
  font-family: $ff_mont;
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 26px;
  color: $white;
  display: flex;
  align-items: center;
  height: 36px;
  padding-left: 20px;
  padding-right: 20px;
}

.dropdown-content a:hover {background-color: rgba(255,255,255,0.2);}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .drop-btn {color: $orange; cursor: pointer;}
.menu-item:hover .drop-btn {color: $orange;}

.dropdown:nth-child(-n+2):hover {
  &:before {
    cursor: pointer;
    content: url(../../common/images/drop-sign-up.png);
  }
}

.cta-button-header {
  width: 229px;
  height: 52px;
  background-image: url(../../common/images/cta-button-grey.png);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  font-family: $ff_mont;
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  padding-left: 29px;
  &.bg_lzy_img {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
  }
  &:hover {
    background-image: url(../../common/images/cta-button-blue-filled.png);
  }
}

//@import "header-1200";