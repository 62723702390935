@import "../../common/scss/variables";

@media screen and (max-width: 1499px) {
  .weird-block {
    .grey-framed-text{
      height: 316px;
      background-image: url(../images/frame-grey-1366.png);
    }
    .wide-row {
      height: 316px;
      .wide-row-col {
        height: 316px;
        &:first-child {
          width: calc((100% - 1134px) / 2 + 50px);
        }
      }
    }
    .cont-row {
      margin-top: -316px;
    }
    .cont-row-col {
      &:first-child {
        p {
          font-size: 19px;
          line-height: 30px;
        }
      }
    }
  }
  .section-logistic-service {
    .exp-row {
      .exp-row-col {
        &:last-child {
          p {
            font-size: 19px;
            line-height: 30px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }

  .section-icons {
    .icon-item {
      p {
        font-size: 19px;
        line-height: 30px;
      }
      &:nth-child(1) {
        p {
          max-width: 330px;
        }
      }
      &:nth-child(2) {
        p {
          max-width: 330px;
        }
      }
      &:nth-child(3) {
        p {

        }
      }
    }
  }
}
