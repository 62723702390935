@import "../../common/scss/variables";

.section-video {
  z-index: -1;
  position: relative;
  .video-wrap {
    z-index: -1;
    //position: absolute;
    video {
      z-index: -1;
      width: 100%;
      height: 662px;
      object-fit: cover;

    }
  }
}

.section-hero {
  //margin-top: -600px;
  //z-index: 1;
  //background-image: url(../images/section-hero-image.png);
  //background-repeat: no-repeat;
  //background-position: center;
  //background-size: cover;
  .h1-wrapper {
    width: 100%;
    padding-top: 80px;
    position: absolute;
    top: 160px;
  }

  h1 {
    width: 649px;
    color: $white;
    margin-bottom: 48px;
  }

  &.bg_lzy_img {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
  }
  .cta-section-hero-wrapper {
    position: absolute;
    top: 495px;
      width: 229px;
    .cta-section-hero {
      width: 229px;
      height: 52px;
      background-image: url(../../common/images/cta-button-orange.png);
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      font-family: $ff_mont;
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      padding-left: 29px;
      color: $white;
      margin-bottom: 40px;
      //transition: 1s;
      &.bg_lzy_img {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
      }
      &:hover {
        background-image: url(../../common/images/cta-button-white-filled.png);
        color: $grey;
      }
    }
  }
}

.weird-block {
  margin-bottom: 27px;
  .page-container {
    height: 311px;
  }

  .wide-row {
    width: 100%;
    display: flex;
    height: 311px;

    .wide-row-col {
      z-index: -1;
      height: 311px;

      &:first-child {
        width: calc((100% - 1134px) / 2 + 10px);
        background-color: $grey;
        margin-top: -113px;
        z-index: 0;
      }

      &:last-child {
        width: 50%;
        background-color: $white;
      }
    }
  }

  .cont-row {
    display: flex;
    margin-top: -311px;
  }

  .cont-row-col {
    z-index: 1;

    &:first-child {
      width: 679px;

      h2 {
        color: $blue;
        margin-bottom: 24px;
        max-width: 517px;
      }

      p {
        font-family: $ff_mont;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        color: $white;
        max-width: 517px;

        span {
          color: $blue;
        }
      }
    }

    &:last-child {
      max-width: 455px;
      padding: 60px 0 0 67px;

      p {
        font-family: $ff_mont;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        color: $grey;
        max-width: 388px;
      }
    }
  }


  .grey-framed-text {
    height: 311px;
    margin-top: -113px;
    z-index: 1;
    padding-top: 40px;
    background-image: url(../images/frame-grey.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &.bg_lzy_img {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
    }
  }
}

.section-logistic-service {
  position: relative;
  .wide-left-lb-bg-tp {
    display: block;
  }
  .wide-row-light-blue {
    top: 261px;
    width: 100%;
    height: 1748px;
    //display: flex;
    position: absolute;
    z-index: -1;
    .wide-row-col-light-blue {
      width: calc((100% - 1132px) / 2);
      height: 100%;
      margin-left: auto;
      background-color: $light_blue;
    }
  }

  .page-container.shape {
    position: relative;
  }

  .shape-complex {
    width: 100%;
    height: 1748px;
    top: 261px;
    z-index: -1;
    position: absolute;
    background-image: url(../images/light-grey-shape-complex.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;

    &.bg_lzy_img {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
    }
  }

  h2 {
    color: $grey;
    text-align: center;
    margin-bottom: 70px;
  }

  .section-logistic-items-sep {
    //padding: 100px 0;
    margin-bottom: 140px;
    h2 {
      text-align: center;
      color: $grey;
      margin-bottom: 70px;
    }
    .logistic-items-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .logistic-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 35px 0;
        width: 358px;
        height: auto;
        background-color: $blue2;

        .logistic-ico {
          width: 100px;
          height: 90px;
          margin-bottom: 25px;
          background-repeat: no-repeat;
          background-size: cover;

          &.bg_lzy_img {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
          }
        }

        p {
          max-width: 261px;
          font-family: $ff_mont;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 38px;
          text-align: center;
          color: $grey;
        }


        &.logistic-item-1 {
          & .logistic-ico {
            background-image: url(../../common/images/logistic-ico-forklift.png);
          }
        }

        &.logistic-item-2 {
          & .logistic-ico {
            background-image: url(../../common/images/logistic-ico-warehouse.png);
          }
        }

        &.logistic-item-3 {
          & .logistic-ico {
            background-image: url(../../common/images/logistic-ico-delivery-truck.png);
          }
        }

        &:hover {
          background-color: $orange;

          & p {
            color: $white;
          }

          &.logistic-item-1 {
            & .logistic-ico {
              background-image: url(../../common/images/logistic-ico-forklift_w.png);
            }
          }

          &.logistic-item-2 {
            & .logistic-ico {
              background-image: url(../../common/images/logistic-ico-warehouse_w.png);
            }
          }

          &.logistic-item-3 {
            & .logistic-ico {
              background-image: url(../../common/images/logistic-ico-delivery-truck_w.png);
            }
          }
        }
      }
    }
  }

  .exp-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;

    .exp-row-col {
      max-height: 346px;

      &:first-child {
        //width: 552px;
        width: 48.68%;
        img {
          width: 100%;
          height: auto;
        }
      }

      &:last-child {
        //width: 457px;
        width: 40.12%;

        p {
          font-family: $ff_mont;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 29px;
          color: $grey;
          margin-bottom: 70px;
          margin-top: -8px;

          span {
            color: $orange;
          }
        }

        .a-wrap {
          margin-left: auto;
          width: 229px;
        }

        .cta-section-logistic-service {
          width: 229px;
          height: 52px;
          background-image: url(../../common/images/cta-button-orange.png);
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          font-family: $ff_mont;
          font-style: italic;
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
          padding-left: 61px;
          color: $white;
          &.bg_lzy_img {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
          }
          &:hover {
            background-image: url(../../common/images/cta-button-blue-filled.png);
            color: $grey;
          }
        }
      }
    }
  }

  .partner-logos-row {
    height: 85px;
    display: flex;
    margin-bottom: 100px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .partner-logo-item {
      margin-bottom: 40px;
      margin-right: 51px;
      &:last-child {
        margin-right: 0;
      }
    }
    &:after {
      content: none;
      flex: unset;
    }
  }

  .square-main-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 156px;

    .square {
      border-style: solid;
      border-image-slice: 1;
      background-color: transparent;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .p-wrapper {
      display: flex;
      flex-direction: column;
    }

    .square-item {
      // homepage width: 358px
      // homepage height: 261px;
      width: 358px;
      height: 261px;
      position: relative;
      background-color: #fff;

      .square-t, .square-r, .square-b, .square-l {
        transition: 1s;
      }

      .square-text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        p {
          width: 264px;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 800;
          text-transform: uppercase;
          text-align: center;
          color: $grey;

          &:first-child {
            font-size: 48px;
            line-height: 77px;

            span {
              font-size: 30px;
              line-height: 48px;
            }
          }

          &:last-child {
            font-size: 18px;
            line-height: 29px;
          }
        }
      }

      &.square-item-1 {
        .square-t {
          width: 72.9%;
          border-top-width: 3px;
          border-image-source: linear-gradient(90deg, #8ED6F7 0%, #46C0F7 100%);
        }

        .square-r {
          height: 0;
          top: 3px;
          border-right-width: 3px;
          border-image-source: linear-gradient(180deg, #47C0F7 0%, #24B9FC 100%);
        }

        .square-b {
          width: 0;
          right: 0;
          border-bottom-width: 3px;
          border-image-source: linear-gradient(180deg, #47C0F7 0%, #24B9FC 100%);
        }

        .square-l {
          border-left-width: 3px;
          border-image-source: linear-gradient(180deg, #8DD6F7 0%, rgba(141, 214, 247, 0) 100%);
        }

        &:hover {
          .square-t {
            width: 100%;
          }

          .square-r {
            height: calc(100% - 3px);
          }

          .square-b {
            width: 100%;
          }

          .square-l {
          }
        }
      }

      &.square-item-2 {
        .square-t {
          border-top-width: 3px;
          border-image-source: linear-gradient(90deg, #8ED6F7 0%, #46C0F7 100%);
        }

        .square-r {
          height: 72.41%;
          top: 3px;
          border-right-width: 3px;
          border-image-source: linear-gradient(180deg, #47C0F7 0%, #24B9FC 100%);
        }

        .square-b {
          width: 0;
          right: 0;
          border-bottom-width: 3px;
          border-image-source: linear-gradient(180deg, #47C0F7 0%, #24B9FC 100%);
        }

        .square-l {
          border-left-width: 3px;
          border-image-source: linear-gradient(180deg, #8DD6F7 0%, rgba(141, 214, 247, 0) 100%);
        }

        &:hover {
          .square-t {
          }

          .square-r {
            height: calc(100% - 3px);
          }

          .square-b {
            width: 100%;
          }

          .square-l {
          }
        }
      }

      &.square-item-3 {
        .square-t {
          border-top-width: 3px;
          border-image-source: linear-gradient(90deg, #8ED6F7 0%, #46C0F7 100%);
        }

        .square-r {
          height: calc(100% - 3px);
          bottom: 0;
          border-right-width: 3px;
          border-image-source: linear-gradient(180deg, #47C0F7 0%, #24B9FC 100%);
        }

        .square-b {
          width: 26.26%;
          right: 0;
          border-bottom-width: 3px;
          border-image-source: linear-gradient(180deg, #47C0F7 0%, #24B9FC 100%);
        }

        .square-l {
          border-left-width: 3px;
          border-image-source: linear-gradient(180deg, #8DD6F7 0%, rgba(141, 214, 247, 0) 100%);
        }

        &:hover {
          .square-t {
          }

          .square-r {
          }

          .square-b {
            width: 100%;
          }

          .square-l {
          }
        }
      }
    }
  }
}

.section-central-complex {
  margin-top: -74px; // todo refactor HOME
  height: 576px;
  z-index: -1;
  background-image: url(../images/cars-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.bg_lzy_img {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
  }
}

.wide-row-central-complex {
  width: 100%;
  height: 268px;
  margin-top: -146px;

  .wide-row-central-complex-col {
    height: 100%;
    width: calc((100% - 1132px) / 2);
    background-color: $grey;
    margin-left: auto;
  }
}

.weird-block-right {
  //margin-bottom: 140px;
  .frame-grey-right {
    width: 546px;
    height: 268px;
    background-image: url(../images/frame-grey-right.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: auto;
    padding: 40px 0;
    margin-top: -268px;
    margin-bottom: -122px;

    h3 {
      width: 455px;
      font-family: $ff_mont;
      font-style: italic;
      font-weight: 800;
      font-size: 30px;
      line-height: 48px;
      color: $white;
      margin-left: auto;
      margin-bottom: 40px;
    }

    .cta-weird-block-right-wrap {
      width: 229px;
      margin-left: auto;
      .cta-weird-block-right {
        width: 229px;
        height: 52px;
        background-image: url(../../common/images/cta-button-orange.png);
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        font-family: $ff_mont;
        font-style: italic;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;
        padding-left: 61px;
        color: $white;
        //z-index: 1;
        &.bg_lzy_img {
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
        }
        &:hover {
          background-image: url(../../common/images/cta-button-white-filled.png);
          color: $grey;
        }
      }

      &.bg_lzy_img {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
      }
    }
  }
}

.section-icons {
  position: relative;
  height: 804px;
  z-index: -1;
  .gb-wide-left {
    position: absolute;
    width: calc((100% - 1132px) / 2);
    height: 804px;
    background-color: $light_blue;
    z-index: -1;
  }

  .section-icons-inner {
    margin-top: -122px;
    padding-top: 263px;
    width: 1134px;
    height: 804px;
    background-image: url(../images/light-grey-shape-left.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top left;

    &.bg_lzy_img {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
    }
  }

  .sec-icons-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .icon-item {
    .ico-title-wrapper {
      height: 45px;
      display: flex;
      margin-bottom: 32px;

      .ico {
        width: 45px;
        height: 45px;
        margin-right: 22px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 29px;
          height: 29px;
        }
      }

      h3 {
        color: $grey;
        width: 233px;
      }
    }

    &:nth-child(1) {
      .ico {
        background-color: $orange;
      }
    }

    &:nth-child(2) {
      .ico {
        background-color: $grey;
      }
    }

    &:nth-child(3) {
      .ico {
        background-color: $blue3;
      }
    }

    p {
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 29px;
      color: $grey;
      max-width: 300px;
    }
  }
}

.section-trucks {
  position: relative;
  padding-top: 54px;
  height: 576px;
  background-image: url(../images/trucks-image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.bg_lzy_img {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
  }

  .wide-row-left-gradient {
    position: absolute;
    height: 176px;
    width: calc((100% - 1134px) / 2);
    background: rgba(96, 97, 94, 0.79);
    z-index: 1;
  }

  h2 {
    font-family: $ff_mont;
    font-style: italic;
    font-weight: 800;
    font-size: 50px;
    line-height: 70px;
    color: $white;
  }

  .left-shape-gradient {
    padding: 54px 0;
    width: 787px;
    height: 176px;
    background-image: url(../images/light-grey-shape-left-gradient.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;

    &.bg_lzy_img {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
    }
  }
}

.homepage-modal {

  #reqOrderModalLayer {
    display: none;
    position: fixed;
    justify-content: center;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 150px;
  }

  .req-form-box {
    max-width: 400px;
    min-width: 300px;
    min-height: 520px;
    height: fit-content;
    background: $white;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 48px 24px;
    .to-hide {
      display: none;
    }

    #reqOrderBtnClose {
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 15px;
      top: 15px;

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  #reqOrderModal-body-ok, #reqOrderModal-body-err, #reqOrderModal-body,  {
    width: 100%;

    .a-wrap {
      width: 100%;
    }

    small {
      display: none;
      position: absolute;
      bottom: 9px;
      left: 7px;
      color: red;
      font-size: 10px;
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 500;
    }

    .cta-button {
      height: 52px;
      width: 100%;
      background-image: url(../../common/images/clc-cta-button-wide.png);
      background-size: 100% 52px;
      background-position: center;
      background-repeat: no-repeat;
      font-family: $ff_mont;
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      line-height: 26px;
      color: $white;
      text-align: center;
      padding-top: 12px;
      margin-top: 25px;

      &.bg_lzy_img {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=);
      }

      &:hover {
        background-image: url(../../common/images/clc-cta-button-wide-blue.png);
      }
    }
  }

  #reqOrderModal-body-ok, #reqOrderModal-body-err {
    max-width: 308px;
    //display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    .cta-button {
      margin-top: 88px;
    }
    img {
      width: auto;
      height: 88px;
      margin-bottom: 25px;
    }

    .p-wrapper {
      margin-top: 50px;
      p {
        font-family: $ff_mont;
        color: $grey;
        text-align: center;

        &:first-child {
          font-style: italic;
          font-weight: 800;
          font-size: 22px;
          line-height: 35px;
          margin-bottom: 12px;
        }

        &:last-child {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 29px;
          margin-bottom: 15px;
        }
      }
    }

  }

  #reqOrderModal-body {
    //display: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 308px;

    .form-title {
      margin: 0 auto;
    }

    input, textarea {
      min-width: 290px;
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      border-bottom: 1px solid $grey_border;
      margin-bottom: 24px;
      padding-left: 7px;
      color: $grey;
    }

    .floating-label {
      position: absolute;
      pointer-events: none;
      left: 7px;
      top: 5px;
      transition: 0.2s ease all;
      font-family: $ff_mont;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: $light_grey2;
    }

    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label,
    input[type=email]:not(:placeholder-shown):not(:focus) ~ .floating-label,
    textarea:focus ~ .floating-label,
    textarea:not(:focus):valid ~ .floating-label {
      top: -15px;
      left: 7px;
      font-size: 12px;
      line-height: 25px;
      color: #60615E;
      opacity: 0.5;
    }

    .inp-group {
      position: relative;

      &.field-alert {
        small {
          display: block;
        }

        input {
          border-bottom: 1px solid red;
        }
      }
    }

    .form-title {
      font-family: $ff_mont;
      font-style: italic;
      font-weight: 800;
      font-size: 22px;
      line-height: 35px;
      text-align: center;
      color: $blue;
      margin-bottom: 32px;
    }
  }

}