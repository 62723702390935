h1, h2, h3, p, li, li span {
  font-stretch: 100%;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3 {
  font-family: $ff_mont;
  font-style: italic;
  font-weight: 800;
}

h1 {
  font-size: 50px;
  line-height: 70px;
}

h2 {
  font-size: 44px;
  line-height: 62px;
}

h3 {
  font-size: 30px;
  line-height: 48px;
}

body {
  font-family: $ff_mont;
}

//p { todo refactor p FF and FS
//  font-family: $ff_mont;
//  font-style: normal;
//}