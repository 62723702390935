$ff_robo: 'Roboto', sans-serif;
$ff_mont: 'Montserrat', sans-serif;

$grey: #60615E;
$light_grey: #9D9D9D;
$blue: #8DD6F7;
$blue2: #A4E3FF;
$blue3: #46C0F7;
$blue4: #3AB7EF;
$blue5: #BBEAFF;
$orange: #F3721F;
$white: #FFF;
$light_blue: #F0F3F6;
$light_blue2: #CED8E1;
$grey_footer: #686965;
$light_grey2: #A9A9A9;
$grey_border: #757679;
