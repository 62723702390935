@import "../../common/scss/variables";

@media screen and (max-width: 1199px) {

  .weird-block {
    .grey-framed-text{
      background-size: 568px 316px;
    }
    .wide-row {
      .wide-row-col {
        &:first-child {
          width: calc((100% - 960px) / 2 + 10px);
        }
      }
    }

    .cont-row-col {
      &:first-child {
        p {
          font-size: 18px;
          line-height: 29px;
          max-width: 500px;
        }
      }
    }
  }

  .section-logistic-service {
    .wide-row-light-blue {
      .wide-row-col-light-blue {
        // width: calc((100% - 960px) / 2 + 10px);
        width: calc((100% - 960px) / 2);
      }
    }

    .shape-complex {
      background-size: cover;
    }

    .section-logistic-items-sep {
      .logistic-items-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .logistic-item {
          width: 300px;
        }
      }
    }

    .exp-row {
      .exp-row-col {
        &:last-child {
          //width: 457px;
          width: 45%;
        }
      }
    }

    .partner-logos-row {
      height: auto;
      margin-bottom: 60px;
      &:after {
        content: "";
        flex: auto;
      }
    }
    .square-main-wrapper {
      .square-item {
        // homepage width: 358px
        // homepage height: 261px;
        width: 300px;
        height: 261px;

        .square-text {
          p {
            &:first-child {
              font-size: 47px;

              span {
                font-size: 29px;
              }
            }

            &:last-child {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  .wide-row-central-complex {
    .wide-row-central-complex-col {
      // width: calc((100% - 960px) / 2 + 10px);
      width: calc((100% - 960px) / 2);
    }
  }

  .section-icons {
    .gb-wide-left {
      position: absolute;
      // width: calc((100% - 960px) / 2);
      width: calc((100% - 960px) / 2);
    }
    .section-icons-inner {
      width: 960px;
      background-size: cover;
    }
    .icon-item {
      max-width: 300px;
      .ico-title-wrapper {
        height: 45px;
        display: flex;
        margin-bottom: 32px;
      }

      p {
        max-width: 300px;
      }

      &:nth-child(1) {
        p {
          max-width: unset;
        }
        .ico {
          background-color: $orange;
        }
      }

      &:nth-child(2) {
        .ico {
          background-color: $grey;
        }
      }

      &:nth-child(3) {
        .ico {
          background-color: $blue3;
        }
      }

      p {
        max-width: unset;
      }

    }
  }

  .section-trucks {
    .wide-row-left-gradient {
      width: calc((100% - 960px) / 2);
    }
  }
}

@media screen and (max-width: 1023px) {

  .section-hero {
    .h1-wrapper {
      width: 720px;
    }
    h1 {
      width: 100%;
    }
  }

  .weird-block {
    .grey-framed-text{
      height: 400px;
      background-size: 417px 400px;
    }
    .wide-row {
      .wide-row-col {
        height: 400px;
        &:first-child {
           width: calc((100% - 720px) / 2 + 50px);
          // width: calc((100% - 720px) / 2);
        }
      }
    }

    .cont-row-col {
      &:first-child {
        p {
          font-size: 17px;
          line-height: 28px;
          max-width: 350px;
        }
      }
      &:last-child {
        max-width: 100%;
        padding: 60px 0 0 0;

        p {
          font-size: 17px;
          line-height: 29px;
          max-width: 300px;
        }
      }
    }
  }

  .section-logistic-service {
    .exp-row {
      .exp-row-col {
        &:last-child {
          p {
            font-size: 17px;
            line-height: 28px;
            margin-bottom: 32px;
          }
        }
      }
    }
    .wide-row-light-blue {
      .wide-row-col-light-blue {
        //width: calc((100% - 720px) / 2 + 10px);
        width: calc((100% - 720px) / 2);
      }
    }

    .shape-complex {
      background-size: cover;
    }

    .section-logistic-items-sep {
      .logistic-items-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        //flex-wrap: wrap;

        .logistic-item {
          width: 220px;
          .logistic-ico {
            width: 80px;
            height: 72px;
            margin-bottom: 15px;
            background-size: 80px 72px;
          }
          p {
            max-width: 200px;
            font-size: 23px;
            line-height: 30px;
          }
        }

      }
    }
    .square-main-wrapper {
      .square-item {
        // homepage width: 358px
        // homepage height: 261px;
        width: 220px;
        height: 220px;

        .square-text {
          p {
            width: 200px;
            &:first-child {
              font-size: 30px;
              line-height: 50px;

              span {
                font-size: 20px;
              }
            }

            &:last-child {

              font-size: 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .wide-row-central-complex {
    .wide-row-central-complex-col {
      // width: calc((100% - 720px) / 2 + 10px);
      width: calc((100% - 720px) / 2);
    }
  }

  .section-icons {
    height: 803px;
    .gb-wide-left {
      // width: calc((100% - 720px) / 2 + 10px);
      width: calc((100% - 720px) / 2);
    }
    .section-icons-inner {
      width: 720px;
    }
    .icon-item {
      max-width: 220px;
      .ico-title-wrapper {
        height: 45px;
        display: flex;
        margin-bottom: 32px;
      }

      p {
        max-width: 200px;
        font-size: 16px;
        line-height: 27px;
      }

      &:nth-child(1) {
        p {
          max-width: unset;
        }
        .ico {
          background-color: $orange;
        }
      }

      &:nth-child(2) {
        .ico {
          background-color: $grey;
        }
      }

      &:nth-child(3) {
        .ico {
          background-color: $blue3;
        }
      }

      p {
        max-width: unset;
      }

    }
  }

  .section-trucks {
    .left-shape-gradient {
      width: 100%;
      background-size: 100% 176px;
    }
    .wide-row-left-gradient {
      width: calc((100% - 720px) / 2);
    }
  }
}

@media screen and (max-width: 767px) {

  .section-hero {
    .h1-wrapper {
      width: 340px;
    }
    h1 {
      width: 100%;
      font-size: 40px;
      line-height: 54px;
    }
    .cta-section-hero-wrapper {
      top: 560px;
    }
  }

  .weird-block {
    margin-bottom: 325px;
    .wide-row {
      .wide-row-col {
        &:first-child {
          width: calc((100% - 420px) / 2 + 10px);
        }
      }
    }

    .wide-row {
      width: 100%;
      display: block;
      height: auto;

      .wide-row-col {
        width: 100%;
        z-index: -1;
        height: 400px;

        &:first-child {
          // width: calc((100% - 340px) / 2 + 10px);
          width: calc((100% - 320px) / 2);
          //background-color: $grey;
          //margin-top: -113px;
          //z-index: 0;
        }

        &:last-child {
          display: none;
        }
      }
    }

    .cont-row {
      margin-top: -400px;
      display: block;
    }

    .cont-row-col {
      height: 400px;
      &:first-child {
        width: 100%;

        .grey-framed-text{
          //padding-top: ;
          height: 400px;
          background-size: 100% 400px;
        }

        p {
          max-width: 270px;
        }
      }
      &:last-child {
        p {
          max-width: 100%;
          text-align: center;
        }
      }
    }
  }

  .section-logistic-service {
    background-color: $light_blue;
    padding: 70px 0;

    //z-index: -1;
    position: relative;
    .page-container.shape {
      display: none;
    }
    .shape-complex {
      height: 3355px;
    }
    .wide-left-lb-bg-tp {
      display: none;
      z-index: -1;
      position: absolute;
      height: 2900px;
      left: 0;
      //top: 500px;
      bottom: -0;
      // width: calc((100% - 340px) / 2 + 10px);
      width: calc((100% - 340px) / 2);
      background-color: $light_blue;
    }
    h2 {
      font-size: 40px;
      line-height: 50px;
      //margin-bottom: 70px;
    }
    .wide-row-light-blue {
      .wide-row-col-light-blue {
        display: none;
        height: 3355px;
        // width: calc((100% - 340px) / 2 + 10px);
        width: calc((100% - 340px) / 2);
      }
    }
    .section-logistic-items-sep {
      margin-bottom: 100px;
      .logistic-items-wrapper {
        display: flex;
        flex-direction: column;

        .logistic-item {
          margin-bottom: 30px;
          width: 100%;

          p {
            font-size: 24px;
            line-height: 38px;;
          }
        }
      }
    }
    .exp-row {
      display: block;
      justify-content: unset;
      margin-bottom: 80px;

      .exp-row-col {
        max-height: fit-content;

        &:first-child {
          width: 100%;
          img {
            width: 100%;
            height: auto;
            margin-bottom: 50px;
          }
        }

        &:last-child {
          width: 100%;

          p {
            padding-left: 15px;
            font-size: 17px;
            line-height: 28px;
            margin-bottom: 70px;
            margin-top: 0;

          }

          .a-wrap {
            margin: 0 auto;
          }

        }
      }
    }
    .partner-logos-row {
      width: 100%;
      height: auto;
      margin-bottom: 60px;
      align-items: center;
      justify-content: space-between;

      .partner-logo-item {
        width: 50%;
        margin-bottom: 40px;
        margin-right: 0;
        display: flex;
        justify-content: center;
        &:last-child {
          margin: 0 auto;
        }
      }
      &:after {
        content: none;
        flex: unset;
      }
    }
    .square-main-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
      margin-bottom: 0;

      .square-item {
        // homepage width: 358px
        // homepage height: 261px;
        width: 358px;
        height: 261px;
        margin-bottom: 30px;

        .square-text {
          p {
            width: 264px;

            &:first-child {
              font-size: 48px;
              line-height: 77px;

              span {
                font-size: 30px;
                line-height: 48px;
              }
            }

            &:last-child {
              font-size: 18px;
              line-height: 29px;
            }
          }
        }
      }
    }
  }

  .weird-block-right {
    //margin-bottom: 140px;
    .frame-grey-right {
      width: 100%;
      height: 268px;
      background-size: 100% 268px;
      margin-left: auto;
      padding: 40px 0;
      margin-top: -268px;
      margin-bottom: -122px;

      h3 {
        width: 58%;
        font-size: 29px;
        line-height: 43px;
        margin: 0 auto 30px;
      }

      .cta-weird-block-right-wrap {
        width: 229px;
        margin-left: 80px;
      }
    }
  }

  .wide-row-central-complex {
    .wide-row-central-complex-col {
      // width: calc((100% - 340px) / 2 + 10px);
      width: calc((100% - 320px) / 2 + 10px);
    }
  }

  .section-icons {
    position: relative;
    height: auto;
    z-index: -1;
    .gb-wide-left {
      position: absolute;
      width: calc((100% - 340px) / 2);
      height: 804px;
      z-index: -1;
    }

    .section-icons-inner {
      margin-top: -122px;
      padding-top: 200px;
      width: 100%;
      height: auto;
      background-size: cover;
    }

    .sec-icons-wrapper {
      display: block;
      justify-content: unset;
      padding: 0 0 100px 0;
    }

    .icon-item {
      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }

      p {
        font-size: 17px;
        line-height: 28px;
        max-width: 100%;
      }
    }
  }

  .section-central-complex {
    margin-top: 0;
    z-index: -1;
  }

  .section-trucks {
    .wide-row-left-gradient {
      width: calc((100% - 340px) / 2);
    }

    h2 {
      font-size: 40px;
      line-height: 56px;
    }

    .left-shape-gradient {
      padding: 30px 0 0;
    }
  }
}

@media screen and (max-width: 419px) {
  .section-logistic-service {
    .square-main-wrapper {
      width: 100%;

      .square-item {
        width: 100%;
      }
    }
  }

  .section-trucks {
    .wide-row-left-gradient {
      width: calc((100% - 320px) / 2);
    }
  }
}




