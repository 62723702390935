 @import "variables";

@media screen and (max-width: 1365px) {

  footer {
    .section-footer {
      position: relative;
      .wide-darkgrey-bg {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: calc((100% - 1134px)/2);
        background-color: $grey;
      }
      .h3-footer {
        width: 100%;
        padding-right: 30px;
      }
    }
    .page-container-footer {
      width: 100%;
      margin: 0;
      //height: 100%;
    }

    .page-container-slim {
      width: 1134px;
      margin: 0 auto;
    }

    .footer-menu-wrapper {
      width: 218px;
      margin: 0 auto;
    }

    .footer-row-main {
      .footer-col-main {
        &:first-child {
          width: 754px;
        }

        &:last-child {
          width: 380px;
        }
      }
    }

    .footer-row-sec-col {
      &:first-child {
        width: 300px;
        margin-right: 50px;
      }

      &:last-child {
        .footer-item {
          & .a-height-100 {
            height: 100%;
          }

          width: 311px;
          &:nth-child(-n+2) {
            margin-bottom: 34px;
          }

          &:nth-child(3) {
            position: relative;
            padding-left: 21px;
            margin-bottom: 42px;

            &:before {
              position: absolute;
              width: 11px;
              height: 15px;
              left: 0;
              top: 5px;
              content: url(../../common/images/map-marker-blue.png);
            }
          }

          &:nth-child(3):hover {
            &:before {
              content: url(../../common/images/map-marker-orange.png);
            }

            & a {
              color: $orange;
            }
          }

          &:nth-child(4) {
            margin-bottom: 48px;
          }
        }
      }
    }

    .section-bottom-bar {
      .cols-wrapper {
        .col {
          &:last-child {
            width: 331px;
            .p-wrapper {
              width: 218px;
            }
          }
        }
      }
    }

  }

}
