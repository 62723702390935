@import "variables";

@media screen and (max-width: 1199px) {
  footer {
    .page-container-footer {
      width: 100%;
      margin: 0;

      .page-container-slim {
        width: 960px;
        margin: 0 auto;
      }
    }

    .footer-row-main {
      .footer-col-main {
        &:first-child {
          width: 660px;
        }

        &:last-child {
          width: 300px;
        }
      }
    }

    .footer-row-sec-col {
      width: unset;
      &:first-child {
        width: 300px;
        margin-right: 20px;
      }

      &:last-child {
        width: 311px;
      }
    }

    .section-footer {
      .wide-darkgrey-bg {
        width: calc((100% - 960px) / 2);
      }
      .orange-line {
        width: calc(960px - 291px + ((100% - 960px)/2) + 25px);
      }
    }
  }
}

@media screen and (max-width: 1023px) {

  footer {
    .page-container-footer {
      .page-container-slim {
        width: 720px;
        margin: 0 auto;
      }
    }

    .h3-footer {
      font-size: 28px;
      line-height: 43px;
    }

    .footer-row-main {
      .footer-col-main {
        &:first-child {
          width: 360px;
        }

        &:last-child {
          width: 360px;
          padding: 185px 0 73px;
        }
      }
    }

    .footer-row-sec {
      flex-wrap: wrap;
    }

    .footer-row-sec-col {
      width: unset;
      &:first-child {
        width: 340px;
        margin-right: 0;
      }

      &:last-child {
        width: 340px;
      }
    }

    .section-footer {
      .wide-darkgrey-bg {
        width: calc((100% - 720px) / 2);
      }
      .orange-line {
        width: calc(720px - 291px + ((100% - 720px)/2) + 25px);
      }
      .cta-soc-wrapper {
        position: relative;
        left: unset;
        right: unset;
        bottom: unset;
        top: unset;
        margin: 0 auto 90px;
        width: fit-content;
        &.one {
          display: none;
        }
        &.two {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  footer {
    .page-container-footer {
      width: 100%;
      margin: 0;

      .page-container-slim {
        width: 380px;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        height: auto;
      }
    }

    .footer-row-main {
      justify-content: unset;
      display: block;

      .footer-col-main {
        &:first-child {
          width: 340px;
        }

        &:last-child {
          width: 340px;
          padding: 70px 0 73px;
        }
      }
    }

    .section-footer {
      .wide-darkgrey-bg {
        height: 715px;
        right: 0;
        bottom: 0;
        top: unset;
        // width: calc((100% - 420px) / 2 + 65px);
        width: calc((100% - 300px) / 2);
      }

      .wide-darkgrey-bg-l {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 715px;
        // width: calc((100% - 420px) / 2 + 65px);
        width: calc((100% - 300px) / 2);
        background-color: $grey;
      }

      .orange-line {
        //width: calc(420px - 291px + ((100% - 420px) / 2) + 25px);
        width: calc(340px - 291px + ((100% - 340px) / 2) + 25px);
      }

      .h3-footer {
        width: 100%;
        padding-right: 0;
      }
    }

    .section-bottom-bar {
      height: 100px;
      .cols-wrapper {
        display: block;

        .col {
          display: block;
          text-align: center;
          justify-content: center;
          height: 100%;

          &:first-child {
            width: auto;

            .p-wrapper {
              width: 100%;
              padding: 6px 0;

            }
          }

          &:last-child {
            width: auto;

            .p-wrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 419px) {
  footer {
    .page-container-footer {
      width: 100%;
      margin: 0;

      .page-container-slim {
        width: 320px;
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .footer-row-main {
      .footer-col-main {
        &:first-child {
          width: 300px;
        }

        &:last-child {
          width: 300px;
          padding: 70px 0 73px;
        }
      }
    }
    .footer-row-sec-col {
      width: unset;

      &:first-child {
        width: 300px;
      }

      &:last-child {
        width: 300px;
      }

      &:last-child {
        .footer-item {
          width: 300px;
        }
      }
    }
  }
}
